const PrivacyPolicy = () => {
  return (
    <>
      <div className="md:my-24 md:mx-80">
        <div className="py-12 px-6 text-sm text-gray-600">
          <div id="wbars">
            <div className="iub_content simple_pp">
              <div className="iub_header">
                <p className="text-2xl xl:text-3xl mb-4">
                  Privacy Policy of <strong>l3arn.org</strong>
                </p>
                <p>This Application collects some Personal Data from its Users.</p>
              </div>{" "}
              {/* /header */}
              <p className="text-xl xl:text-2xl mb-2">
                Personal Data processed for the following purposes and using the
                following services:
              </p>
              <ul className="for_boxes cf">
                <li className="one_line_col">
                  <ul className="for_boxes">
                    <li>
                      <div className="iconed policyicon_purpose_16">
                        <h4>Access to third-party accounts</h4>
                        <ul className="unstyled">
                          <li>
                            <h4>Facebook account access</h4>
                            <p>Permissions: Photos</p>
                          </li>
                          <li>
                            <h4>Stripe account access</h4>
                            <p>
                              Personal Data: email address; first name; last name;
                              password; payment info; Tracker; Usage Data; username
                            </p>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="iconed policyicon_purpose_5">
                        <h4>Analytics</h4>
                        <ul className="unstyled">
                          <li>
                            <h4>Google Analytics</h4>
                            <p>Personal Data: Tracker; Usage Data</p>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="iconed policyicon_purpose_10">
                        <h4>Contacting the User</h4>
                        <ul className="unstyled">
                          <li>
                            <h4>Mailing list or newsletter</h4>
                            <p>
                              Personal Data: date of birth; email address; first
                              name; gender; last name
                            </p>
                          </li>
                          <li>
                            <h4>Contact form</h4>
                            <p>
                              Personal Data: country; county; date of birth; email
                              address; first name; gender; ZIP/Postal code
                            </p>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="iconed policyicon_purpose_21">
                        <h4>Handling payments</h4>
                        <ul className="unstyled">
                          <li>
                            <h4>Payments processed via the Google Play Store</h4>
                            <p>Personal Data: email address; payment info</p>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="iconed policyicon_purpose_42">
                        <h4>Hosting and backend infrastructure</h4>
                        <ul className="unstyled">
                          <li>
                            <h4>Firebase Cloud Functions</h4>
                            <p>
                              Personal Data: Usage Data; various types of Data as
                              specified in the privacy policy of the service
                            </p>
                          </li>
                          <li>
                            <h4>Contentful</h4>
                            <p>Personal Data: various types of Data</p>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="iconed policyicon_purpose_18">
                        <h4>Managing contacts and sending messages</h4>
                        <ul className="unstyled">
                          <li>
                            <h4>Firebase Notifications</h4>
                            <p>
                              Personal Data: various types of Data as specified in
                              the privacy policy of the service
                            </p>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="iconed policyicon_purpose_46">
                        <h4>Platform services and hosting</h4>
                        <ul className="unstyled">
                          <li>
                            <h4>Google Play Store</h4>
                            <p>Personal Data: Usage Data</p>
                          </li>
                          <li>
                            <h4>App Store Connect</h4>
                            <p>Personal Data: User ID</p>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="iconed policyicon_purpose_9">
                        <h4>Registration and authentication</h4>
                        <ul className="unstyled">
                          <li>
                            <h4>Firebase Authentication</h4>
                            <p>
                              Personal Data: first name; last name; password;
                              profile picture; social media accounts ; username
                            </p>
                          </li>
                          <li>
                            <h4>Facebook Authentication</h4>
                            <p>
                              Personal Data: Tracker; various types of Data as
                              specified in the privacy policy of the service
                            </p>
                          </li>
                          <li>
                            <h4>Google OAuth</h4>
                            <p>
                              Personal Data: various types of Data as specified in
                              the privacy policy of the service
                            </p>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="iconed policyicon_purpose_40">
                        <h4>Social features</h4>
                        <ul className="unstyled">
                          <li>
                            <h4>Firebase Dynamic Links</h4>
                            <p>
                              Personal Data: various types of Data as specified in
                              the privacy policy of the service
                            </p>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="iconed policyicon_purpose_48">
                        <h4>Tag Management</h4>
                        <ul className="unstyled">
                          <li>
                            <h4>Google Tag Manager</h4>
                            <p>Personal Data: Usage Data</p>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <h2 id="opt_out">
                Information on opting out of interest-based advertising
              </h2>
              <p>
                In addition to any opt-out feature provided by any of the services
                listed in this document, Users may learn more on how to generally
                opt out of interest-based advertising within the dedicated section
                of the Cookie Policy.
              </p>
              <h2 id="further_data">
                Further information about the processing of Personal Data
              </h2>
              <ul className="for_boxes cf">
                <li className="one_line_col wide">
                  <ul className="for_boxes">
                    <li>
                      <div className="iconed icon_general">
                        <h4>Push notifications</h4>
                        <p>
                          This Application may send push notifications to the User
                          to achieve the purposes outlined in this privacy policy.
                        </p>
                        <p>
                          Users may in most cases opt-out of receiving push
                          notifications by visiting their device settings, such as
                          the notification settings for mobile phones, and then
                          change those settings for this Application, some or all of
                          the apps on the particular device.
                          <br />
                          Users must be aware that disabling push notifications may
                          negatively affect the utility of this Application.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="iconed icon_general">
                        <h4>
                          Push notifications based on the User's geographic location
                        </h4>
                        <p>
                          This Application may use the User's geographic location to
                          send push notifications for the purposes outlined in this
                          privacy policy.
                        </p>
                        <p>
                          Users may in most cases opt-out of receiving push
                          notifications by visiting their device settings, such as
                          the notification settings for mobile phones, and then
                          changing those settings for some or all of the apps on the
                          particular device.
                        </p>
                        <p>
                          Users must be aware that disabling push notifications may
                          negatively affect the utility of this Application.
                        </p>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <h2 id="contact_information">Contact information</h2>
              <ul className="for_boxes cf">
                <li className="one_line_col">
                  <ul className="for_boxes">
                    <li>
                      <div className="iconed icon_owner">
                        <h4>Owner and Data Controller</h4>
                        <p>Floor 1, Flat/Office 101, Stasandrou 7, 1060 Nicosia, Cyprus</p>
                        <p>
                          <strong>Owner contact email:</strong> support@l3arn.org
                        </p>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="iub_footer">
                <p>Latest update: March 20, 2023</p>
              </div>{" "}
              {/* /footer */}
            </div>{" "}
            {/* /content */}
          </div>{" "}
          {/* /wbars */}
        </div>{" "}
        {/* /container base_container */}
      </div>{" "}
      {/* /wbars_wrapper */}
    </>
  );
};

export default PrivacyPolicy;
import React from "react";
import studentImage from "../assets/popupimages/student.jpg";
import parentImage from "../assets/popupimages/parent.jpg";
import tutorImage from "../assets/popupimages/tutor.jpg";
import swotbuddyImage from "../assets/popupimages//swotbuddy.jpg";
import rightArrow from "../assets/right-arrow.svg";
import { useSpring, useInView, animated, useTrail } from "@react-spring/web";

const PartThree = ({ showPopup }) => {
  const [refV, boxSprings] = useInView(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
    }),
    { root: null, rootMargin: "-20px", threshold: 0.3, once: true }
  );

  const [childContainerRef, childContainerInView] = useInView({
    root: null,
    rootMargin: "-20px",
    threshold: 0.3,
    once: true,
  });
  const textContent = [
    {
      title: "Interactive Learning Tools",
      image: studentImage,
      text: "Dive into a world of interactive learning with tools designed to engage and inspire. From AI-powered quizzes to virtual labs, explore how l3arn makes learning dynamic and fun.",
      button: "start to learn",
    },
    {
      title: "Customized Education Paths",
      image: parentImage,
      text: "Discover personalized learning journeys tailored to your interests and goals. Explore various courses and modules that adapt to your learning style and pace, ensuring an education that fits you perfectly.",
    },
    {
      title: "AI-Assisted Tutoring",
      image: tutorImage,
      text: "Experience the future of tutoring with AI assistance. Our platform offers real-time help, content suggestions, and performance tracking to enhance both teaching and learning experiences.",
    },
    {
      title: "Collaborative Learning Spaces",
      image: swotbuddyImage,
      text: "Join our collaborative learning spaces where ideas thrive. Explore forums, group projects, and peer-to-peer learning opportunities that foster community and shared knowledge.",
    },
  ];

  const trails = useTrail(textContent.length, {
    config: { duration: 200 },
    from: { y: 30, opacity: 0 },
    to: childContainerInView ? { y: 0, opacity: 1 } : {},
    delay: (key) => {
      return 500;
    },
  });

  const openPopup = (content) => {
    showPopup({
      image: content.image,
      text: content.text,
      hasButton: content.button ? true : false,
    });
  };

  return (
    <React.Fragment>
      <animated.div
        id={"explore_learning"}
        className="mt-20 mb-40 "
        ref={refV}
        style={boxSprings}
      >
        <p className="text-3xl text-gray-600 mb-14 text-center">
          Explore Learning
        </p>
        <div
          ref={childContainerRef}
          key={"child-container"}
          className="flex justify-center align-center gap-8 lg:gap-12 flex-wrap"
        >
          {trails.map((styles, index) => (
            <animated.div
              key={index}
              className="flex flex-col justify-between items-start bg-[#FDF3F2] rounded-xl p-6 h-[220px] w-[280px] shadow-md"
              style={styles}
            >
              <h3 className="rounded-2xl bg-[#FFF8F8] text-gray-700 text-xl text-center w-10/12 px-2 py-5">
                {textContent[index].title}
              </h3>
              <button
                className="rounded-xl bg-white shadow-xl place-items-center w-10 h-10 p-3 mt-2"
                onClick={() => openPopup(textContent[index])}
              >
                <img src={rightArrow} alt="right_arrow_icon" />
              </button>
            </animated.div>
          ))}
        </div>
      </animated.div>
    </React.Fragment>
  );
};

export default PartThree;

import Accordion from "../components/accordion";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";
import { useState } from "react";
import CompanyContactModal from "../components/companyContactModal";

const PartEight = () => {
  const navigate = useNavigate();

  const [showFormModal, setShowFormModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const spring = useSpring({
    from: { y: 20, opacity: 0 },
    to: { y: 0, opacity: 1 },
  });
  // const accordionData = [
  //   {
  //     title: "Student",
  //     buttonText: "Start exploring",
  //     accordions: [
  //       {
  //         title: "Tailored Learning",
  //         text: "Enjoy personalized education based on your unique learning matrix, resulting in efficient and rewarding learning experiences.",
  //       },
  //       {
  //         title: "Qualified Tutors",
  //         text: "Access an array of vetted tutors in various subjects, finding the perfect match for your educational needs.",
  //       },
  //       {
  //         title: "24/7 Study Support",
  //         text: "With AI-powered ChatGPT, get round-the-clock study assistance and concept clarification.",
  //       },
  //       {
  //         title: "Accessible Learning Material",
  //         text: "Use dynamic learning resources and assignments custom-made for your learning requirements.",
  //       },
  //       {
  //         title: "Interact and Grow",
  //         text: "Learn and interact within a community of students sharing knowledge and experiences.",
  //       },
  //       {
  //         title: "Free and Seamless",
  //         text: "Enjoy a user-friendly platform with no additional cost beyond your tutor's fees. Single sign-on feature and integrated communication tools offer a smooth and hassle-free learning experience.",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Parent",
  //     buttonText: "Join now",
  //     accordions: [
  //       {
  //         title: "Progress Tracking",
  //         text: "Keep a tab on your child's academic growth and understand their learning patterns.",
  //       },
  //       {
  //         title: "Parental Control",
  //         text: "Get access to an integrated dashboard providing updates on your child's educational progress.",
  //       },
  //       {
  //         title: "Quality Education",
  //         text: "Ensure top-notch education for your child from our network of proficient tutors.",
  //       },
  //       {
  //         title: "Secure Learning",
  //         text: "Rest easy with a trustworthy platform that prioritizes your child's safety.",
  //       },
  //       {
  //         title: "Personalized Learning Plans",
  //         text: "Experience learning plans tailored specifically to your child's needs and learning style.",
  //       },
  //       {
  //         title: "Free and Integrated",
  //         text: "Use our platform at no extra cost with the convenience of single sign-on, and benefit from community support and shared experiences.",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Tutor",
  //     buttonText: "Become a Member",
  //     accordions: [
  //       {
  //         title: "Wide Student Base",
  //         text: "Connect with a diverse range of students seeking your expertise.",
  //       },
  //       {
  //         title: "Organized Scheduling",
  //         text: "Manage your tutoring sessions efficiently with our integrated calendar and booking system.",
  //       },
  //       {
  //         title: "Innovative Tools",
  //         text: "Benefit from tools like automatic assignment generation and AI assistance for effective teaching.",
  //       },
  //       {
  //         title: "Professional Development",
  //         text: "Expand your tutoring practice and skillset while benefiting from just a 10% fee on your income.",
  //       },
  //       {
  //         title: "Community Engagement",
  //         text: "Share and gain teaching strategies within a community of fellow tutors.",
  //       },
  //       {
  //         title: "Secure Payments",
  //         text: "Enjoy seamless, timely, and secure payment transactions.",
  //       },
  //     ],
  //   },
  //   {
  //     title: "SwotBuddy",
  //     buttonText: "Sign up free",
  //     accordions: [
  //       {
  //         title: "Mentorship Role",
  //         text: "Enhance students' learning journeys and contribute meaningfully to their academic success.",
  //       },
  //       {
  //         title: "Engaging Community",
  //         text: "Be part of a vibrant community of swotbuddies, tutors, students, and parents.",
  //       },
  //       {
  //         title: "Skill Enhancement",
  //         text: "Improve your mentoring skills and gain experience in the education technology sector.",
  //       },
  //       {
  //         title: "Valuable Experience",
  //         text: "Acquire rewarding experiences in the ed-tech field, adding value to your professional growth.",
  //       },
  //       {
  //         title: "Flexibility",
  //         text: "Work at your own pace with a role that suits your schedule and commitment level.",
  //       },
  //       {
  //         title: "Making a Difference",
  //         text: "Play a direct role in shaping students' educational journey and success.",
  //       },
  //     ],
  //   },
  // ];

  const subscriptionData = [
    {
      title: "Tutors",
      buttonText: "Start exploring",
      accordions: [
        {
          title: "Advanced Course Creation Tools",
          text: "Access to innovative tools that make course design both efficient and effective, allowing for the integration of multimedia elements and AI-powered content suggestions.",
        },
        {
          title: "AI Assignment Assistance",
          text: "AI tools help create, grade, and provide feedback on assignments, saving time and enhancing the learning experience for students.",
        },
        {
          title: "Professional Development",
          text: "Access to tech-savvy courses specifically designed for educators, focusing on the latest educational technologies and teaching methodologies.",
        },
        {
          title: "Community and Support",
          text: "A dedicated support system and a community of like-minded educators for sharing resources and best practices.",
        },
      ],
    },
    {
      title: "Students",
      buttonText: "Sign up",
      accordions: [
        {
          title: "Personalized Learning Paths",
          text: "AI-driven recommendations for courses and study materials based on individual learning styles and goals.",
        },
        {
          title: "Multimedia Resources",
          text: "Access to a vast library of video, audio, and interactive content designed to cater to diverse learning preferences.",
        },
        {
          title: "AI-Powered Study Aids",
          text: "Tools like flashcards, summaries, and practice quizzes tailored to each student's needs, enhancing retention and understanding.",
        },
        {
          title: "Progress Tracking",
          text: "Real-time insights into progress and areas for improvement, helping students stay on track towards their educational goals.",
        },
      ],
    },
    {
      title: "Institutes",
      buttonText: "Contact us",
      accordions: [
        {
          title: "Bulk Account Management",
          text: "Streamlined management of teacher and student accounts, simplifying enrollment, tracking, and reporting.",
        },
        {
          title: "Custom Branding Options",
          text: "Institutes can customize the look and feel of the platform to reflect their brand, enhancing their visibility and student engagement",
        },
        {
          title: "Detailed Analytics and Reporting",
          text: "Insights into course effectiveness, student engagement, and performance to help tailor educational offerings.",
        },
        {
          title: "Integration with Educational Tools",
          text: 'Seamless integration with tools like "Big Blue Button" by MIT for enhanced virtual classroom experiences.',
        },
      ],
    },
    {
      title: "White Label Clients",
      buttonText: "Contact us",
      accordions: [
        {
          title: "Platform Customization",
          text: "Complete control over platform customization, allowing for a fully branded learning environment.",
        },
        {
          title: "API Access for Integration",
          text: "Easy integration with existing systems and the ability to add custom functionalities.",
        },
        {
          title: "Dedicated Account Management",
          text: "Personalized support to ensure the platform meets all business and educational needs.",
        },
        {
          title: "Scalability",
          text: "The platform is designed to scale, accommodating growth in users and content without compromising performance",
        },
      ],
    },
  ];
  return (
    <div className="mt-20 mb-36" id="enroll">
      <p className="text-3xl text-gray-600 mb-14 text-center">Enroll</p>

      <div className="flex justify-center align-center gap-8 flex-wrap">
        {subscriptionData.map((card, index) => (
          <animated.div
            key={index}
            className="h-[40rem] card flex flex-col justify-around items-center md:items-start bg-[#FAFAFA] rounded-sm p-6 w-[340px] shadow-md overflow-y-auto"
            style={spring}
          >
            <p className="rounded-2xl bg-[#FBFDFC] text-[#f56010bd] text-xl text-center w-10/12 px-2 py-5">
              {card.title}
            </p>
            <Accordion data={card.accordions} />
            <div className="w-full flex items-center justify-center">
              <button
                onClick={() => {
                  if (index >= 2) {
                    setShowFormModal(true);
                  } else {
                    navigate("/on-boarding");
                  }
                }}
                className=" bg-[rgba(156,57,103,1)] mt-12 md:mt-20 w-fit px-6 py-2 text-base text-white font-medium rounded-md shadow-md"
              >
                {card.buttonText}
              </button>
            </div>
          </animated.div>
        ))}
      </div>

      <CompanyContactModal
        ariaHideApp={false}
        isOpen={!loading && showFormModal}
        onRequestClose={() => {
          setShowFormModal(false);
        }}
        showLoader={(status) => {
          setLoading(status);
        }}
      />
    </div>
  );
};

export default PartEight;
